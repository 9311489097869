import type { SignTypedDataVersion, MessageTypes, TypedDataV1, TypedMessage } from '@metamask/eth-sig-util';
import { Auth } from '../auth';
import { AuthError, EIP1559TxData, PrefixedHexString, TxData } from '../types';
import { addHexPrefix } from '../utils/hex-utils';
import { isParticleDev } from '../utils';

class EvmService {
    constructor(private auth: Auth) {}

    private assertEVM() {
        if (this.auth.chain()?.name?.toLowerCase() === 'solana') {
            throw AuthError.unsupportedMethod();
        }
    }

    public async sendTransaction(transaction: TxData | EIP1559TxData): Promise<PrefixedHexString> {
        this.assertEVM();
        return this.auth.sendTransaction(addHexPrefix(Buffer.from(JSON.stringify(transaction)).toString('hex')));
    }

    public async personalSign(message: PrefixedHexString): Promise<PrefixedHexString> {
        this.assertEVM();
        return this.auth.sign('personal_sign', message);
    }

    public async signTypedData<V extends SignTypedDataVersion, T extends MessageTypes>({
        data,
        version,
    }: {
        data: V extends 'V1' ? TypedDataV1 : TypedMessage<T>;
        version: V;
    }): Promise<PrefixedHexString> {
        this.assertEVM();
        return this.auth.sign(
            `eth_signTypedData_${version.toLowerCase()}`,
            addHexPrefix(Buffer.from(JSON.stringify(data)).toString('hex'))
        );
    }

    public async getAddress(): Promise<PrefixedHexString | undefined> {
        return this.auth.getEVMAddress();
    }

    public getRpcUrl(): string {
        this.assertEVM();
        const domain = isParticleDev()
            ? 'https://rpc-debug.particle.network/evm-chain'
            : 'https://rpc.particle.network/evm-chain';
        return `${domain}?chainId=${this.auth.chain().id}&projectUuid=${this.auth.config.projectId}&projectKey=${
            this.auth.config.clientKey
        }`;
    }
}

export default EvmService;
